<template>
  <v-card class="mx-auto" light style="max-height: 500px; overflow-y: auto;">
    <v-card-title>{{ productTitle }}</v-card-title>
    <v-card-subtitle>Pricing History</v-card-subtitle>

    <div v-for="(pricingData, i) of pricingDataArrayFixed" :key="i">
      <v-divider></v-divider>
      <div class="headerRow">
        <div style="max-width: 155px; width: 100%;">
          <b>Pricing Method <br> </b> {{ pricingData[73].value }}
        </div>
        <div style="max-width: 180px; width: 100%;">
          <b>Price Valid Dates <br> </b>
          from: {{ pricingData[12].value }}
          <br>
          to: {{ pricingData[13].value }}
        </div>
        <div style="max-width: 350px; width: 100%;">
          <b>Price Description <br> </b> {{ pricingData[14].value }}
        </div>

        <div v-if="pricingData[24].value" style="max-width: 350px; width: 100%;">
          <b>Student Price <br> </b>
          <span v-if="pricingData[148].value === 'GBP'">£</span>
          <span v-if="pricingData[148].value === 'EURO'">€</span>
          <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[24].value + ' ' + pricingData[148].value }}
        </div>
        <div v-if="pricingData[25].value" style="max-width: 350px; width: 100%;">
          <b>Adult Price <br> </b>
          <span v-if="pricingData[148].value === 'GBP'">£</span>
          <span v-if="pricingData[148].value === 'EURO'">€</span>
          <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[25].value + ' ' + pricingData[148].value }}
        </div>
        <div v-if="pricingData[6].value" style="max-width: 350px; width: 100%;">
          <b>Group Price <br> </b>
          <span v-if="pricingData[148].value === 'GBP'">£</span>
          <span v-if="pricingData[148].value === 'EURO'">€</span>
          <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[6].value + ' ' + pricingData[148].value }}
        </div>

      </div>

      <v-expand-transition>
        <div v-if="show === i" class="extraPricingContainer">
          <v-card-text style="max-width: 400px;">

          <span v-if="pricingData[74].value">
            <b>Price Type: </b>
            <span v-for="item, i of pricingData[74].value" :key="i">{{ item }}, </span>
            <br>
          </span>

          <span v-if="pricingData[8].value">
            <b>Tax:</b> {{ pricingData[8].value * 100 }}%
            <br>
          </span>
          <span v-if="pricingData[75].value">
            <b>Fee:</b> {{ pricingData[75].value * 100 }}%
            <br>
          </span>
          <span v-if="pricingData[82].value">
            <b>Fee Includes:</b> {{ pricingData[82].value }}
            <br>
          </span>
          <span v-if="pricingData[35].value">
            <b>Group Minimum:</b> {{ pricingData[35].value }}
            <br>
          </span>
          <span v-if="pricingData[34].value">
            <b>Group Maximum:</b> {{ pricingData[34].value }}
            <br>
          </span>
          <span v-if="pricingData[80].value">
            <b>Student Age Range:</b>
            {{ pricingData[80].value.split(';')[0] }} -
            {{ pricingData[80].value.split(';')[1] }}
            <br>
          </span>
          <span v-if="pricingData[78].value">
            <b>Child Price: </b>
            <span v-if="pricingData[148].value === 'GBP'">£</span>
            <span v-if="pricingData[148].value === 'EURO'">€</span>
            <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[78].value + ' ' + pricingData[148].value }}
            <br>
          </span>
          <span v-if="pricingData[81].value">
            <b>Child Age Range:</b>
            {{ pricingData[81].value.split(';')[0] }} -
            {{ pricingData[81].value.split(';')[1] }}
            <br>
          </span>
          <span v-if="pricingData[77].value">
            <b>Senior Price: </b>
            <span v-if="pricingData[148].value === 'GBP'">£</span>
            <span v-if="pricingData[148].value === 'EURO'">€</span>
            <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[77].value + ' ' + pricingData[148].value }}
            <br>
          </span>
          <!-- <span v-if="pricingData[79].value">
            <b>Veteran Price: </b>
            <span v-if="pricingData[148].value === 'GBP'">£</span>
            <span v-if="pricingData[148].value === 'EURO'">€</span>
            <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[79].value + ' ' + pricingData[148].value }}
            <br>
          </span> -->
          <span v-if="pricingData[6].value">
            <b>Group Price: </b>
            <span v-if="pricingData[148].value === 'GBP'">£</span>
            <span v-if="pricingData[148].value === 'EURO'">€</span>
            <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[6].value + ' ' + pricingData[148].value }}
            <br>
          </span>
          <span v-if="pricingData[76].value">
            <b>Per Person Fee: </b>
            <span v-if="pricingData[148].value === 'GBP'">£</span>
            <span v-if="pricingData[148].value === 'EURO'">€</span>
            <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[76].value + ' ' + pricingData[148].value }}
            <br>
          </span>
          <span v-if="pricingData[83].value">
            <b>Per Person Fee Includes:</b> {{ pricingData[83].value }}
            <br>
          </span>
          <span v-if="pricingData[89].value">
            <b>Group Fee: </b>
            <span v-if="pricingData[148].value === 'GBP'">£</span>
            <span v-if="pricingData[148].value === 'EURO'">€</span>
            <span v-if="pricingData[148].value !== 'GBP' && pricingData[148].value !== 'EURO'">$</span>{{ pricingData[89].value + ' ' + pricingData[148].value }}
            <br>
          </span>
          <span v-if="pricingData[90].value">
            <b>Group Fee Includes:</b> {{ pricingData[90].value }}
            <br>
          </span>
          <span v-if="pricingData[36].value">
            <b>TD Complimentary:</b> {{ pricingData[36].value }}
            <br>
          </span>
          <span v-if="pricingData[38].value">
            <b>Driver Complimentary:</b> {{ pricingData[38].value }}
            <br>
          </span>
          <span v-if="pricingData[39].value">
            <b>Complimentary Details:</b> {{ pricingData[39].value }}
            <br>
          </span>
          <!-- <span v-if="pricingData[87].value">
            <b>Complimentary Type:</b> {{ pricingData[87].value }}
            <br>
          </span> -->
          <!-- <span v-if="pricingData[88].value">
            <b>Complimentary Ratio:</b> {{ pricingData[88].value }}
            <br>
          </span> -->
          </v-card-text>
        </div>
      </v-expand-transition>

      <v-card-actions style="padding: 15px 20px;">
        <v-spacer></v-spacer>
        <v-btn style="background: #283c86; color: #fff;" v-if="show === null" @click="show = i">
          Show More
        </v-btn>
        <v-btn v-if="show === i" @click="show = null">
          Show Less
        </v-btn>
      </v-card-actions>
    </div>

    <v-card-actions>
      <v-btn color="purple" text @click="emitClosePricing">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PricingTable',
  props: {
    productTitle: String,
    pricingData: Object,
    pricingDataArray: Array,
  },
  computed: {
    cleanedPVD() {
      return this.priceValidDates;
    },
    pricingDataArrayFixed() {
      return [...this.pricingDataArray].reverse();
    },
  },
  data() {
    return {
      show: null,
    };
  },
  methods: {
    emitClosePricing() {
      this.$emit('child-data');
    },
  },
};
</script>

<style scoped>
  .headerRow {
    display: flex; justify-content: space-between; padding: 10px;
  }
  .headerRow > div {
    padding: 5px 20px;
  }
  .extraPricingContainer {
    height: 150px;
    overflow-y: scroll;
    max-width: 550px;
    width: 100%;
    background-color: #F8F0E3;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 0 15px;
  }
  @media only screen and (max-width: 600px) {
    .headerRow {
      flex-direction: column;
      width: 90vw;
    }
    .extraPricingContainer {
      width: 90%;
    }
  }
</style>
